<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Users</h3>
    </div>
    <div class="border-bottom">
      <div class="container p-4">
        The following is a list of users who are registered, most of which are contributing data to
        Airframes via feeder clients that they run at their locations. Other users are here to
        search and use the data provided by the community. This updates
        <strong>update every 5 seconds</strong> and reflects near realtime status of these users.
      </div>
    </div>
    <div class="container p-4">
      <div v-if="users.length > 0">
        <UsersTable :users="users" class="bg-white" />
      </div>
      <div v-else>
        Getting users list...
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import UsersTable from '@/components/users/UsersTable.vue';

@Component({
  components: {
    UsersTable,
  },
})
export default class Users extends Vue {
  users = [];

  fetchingUsers = false;

  errorFetchingUsers = false;

  fetchInterval = null;

  refresh() {
    this.fetchUsers();
  }

  mounted() {
    this.refresh();
    this.clearIntervals();
    this.fetchInterval = window.setInterval(() => {
      this.refresh();
    }, 5000);
  }

  beforeDestroy() {
    this.clearIntervals();
  }

  clearIntervals() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  fetchUsers() {
    this.fetchingUsers = true;
    const url = 'https://api.airframes.io/users';
    Vue.axios.get(url).then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        this.fetchingUsers = false;
        this.errorFetchingUsers = true;
      } else {
        this.users = response.data;
        this.fetchingUsers = false;
      }
    }).catch((error) => {
      console.error(error);
      this.fetchingUsers = false;
    });
  }
}
</script>
