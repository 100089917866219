var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"user-settings text-center"},[_c('div',{staticClass:"mt-0 pt-3 pb-3 bg-white border-bottom",attrs:{"id":"nav"}},[_c('h3',{staticClass:"m-0"},[_vm._v("Settings")]),_c('router-link',{attrs:{"to":"/user/dashboard"}},[_vm._v("Account")]),_vm._v(" | "),_c('router-link',{attrs:{"to":"/user/settings"}},[_vm._v("Settings")]),_vm._v(" | Public Profile ")],1),_c('div',{staticClass:"mb-4 p-4 border-bottom"},[_c('div',{staticClass:"container text-left"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-3"},[_c('UserSettingsNav')],1),_c('div',{staticClass:"col"},[_c('h3',[_vm._v(" Public Profile ")]),_c('hr'),_c('div',{staticClass:"mb-4"},[_vm._m(0),_c('input',{staticClass:"form-control",attrs:{"id":"username","name":"username","type":"text","disabled":"true"},domProps:{"value":_vm.$store.state.auth.user.username}})]),_c('div',{staticClass:"mb-4"},[_vm._m(1),_c('input',{staticClass:"form-control",attrs:{"id":"name","name":"name","type":"text","placeholder":"John Doe"},domProps:{"value":_vm.$store.state.auth.user.name}})]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('strong',[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('strong',[_c('label',{attrs:{"for":"name"}},[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_c('strong',[_c('label',{attrs:{"for":"bio"}},[_vm._v("Bio")])]),_c('input',{staticClass:"form-control",attrs:{"id":"bio","name":"bio","type":"text","placeholder":"Radio addict and licensed ham radio enthusiast"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_c('strong',[_c('label',{attrs:{"for":"location"}},[_vm._v("Location")])]),_c('input',{staticClass:"form-control",attrs:{"id":"location","name":"location","type":"text","placeholder":"San Francisco, CA"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_c('strong',[_c('label',{attrs:{"for":"website_url"}},[_vm._v("Website URL")])]),_c('input',{staticClass:"form-control",attrs:{"id":"website_url","name":"website_url","type":"text","placeholder":"https://mywebsite.com/johndoe"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_c('strong',[_c('label',{attrs:{"for":"x_username"}},[_vm._v("Twitter/X Username")])]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("https://x.com/")]),_c('input',{staticClass:"form-control",attrs:{"id":"x_username","name":"x_username","type":"text","placeholder":"johndoe"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_c('strong',[_c('label',{attrs:{"for":"mastodon_url"}},[_vm._v("Mastodon URL")])]),_c('input',{staticClass:"form-control",attrs:{"id":"mastodon_url","name":"mastodon_url","type":"text","placeholder":"https://airwaves.social/@johndoe"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-0"},[_c('strong',[_c('label',{attrs:{"for":""}},[_vm._v("Avatar")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_vm._v(" Avatars are currently provided by "),_c('a',{attrs:{"href":"https://gravatar.com/","target":"_blank"}},[_vm._v("Gravatar")]),_vm._v(" which is based on your account's email address. You can set your avatar at "),_c('a',{attrs:{"href":"https://gravatar.com/","target":"_blank"}},[_vm._v("Gravatar")]),_vm._v(". In the future we will allow users to upload their own avatars to override this. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-4"},[_c('input',{staticClass:"btn btn-primary",attrs:{"type":"submit","value":"Save"}})])
}]

export { render, staticRenderFns }