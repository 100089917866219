<template>
  <div class="bg-white text-muted">
    <div class="row">
      <div class="col-12">
        <div class="px-1">
          <small>
            <div class="flex p-2 float-left align-middle">
              <span v-if="message.source_type" :title="message.source" class="align-middle">
                {{ message.source_type.toUpperCase() }}
              </span>
              <span v-else class="align-middle">
                UNKNOWN
              </span>
              <span v-if="!!message.station && !!message.station.ident" class="align-middle">
                from station
                <a
                  :href="`/stations/${message.station.ident}`"
                  @click.stop
                  target="_blank"
                  >{{ message.station.ident }}</a>
              </span>
              <span v-if="!!message.station.user" class="">
                by
                <a
                  :href="`/users/${message.station.user.username}`"
                  @click.stop
                  target="_blank"
                  >
                  <img
                    :src="message.station.user.gravatarUrl"
                    :alt="`Avatar for ${message.station.user.username}`"
                    class="rounded-circle align-middle"
                    width="20"
                    height="20"
                    />
                  {{ message.station.user.username }}
                </a>
              </span>
            </div>
            <div class="p-2 float-right align-middle">
              {{ message.timestamp | moment('MMM DD YYYY, HH:mm:ss') }}
            </div>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessageItemHeader extends Vue {
  @Prop() message!: any;
}
</script>

<style scoped>
</style>
