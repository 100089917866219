<template>
  <div class="flex-row border-bottom" style="height: 56px;">
    <b-navbar toggleable="md" type="dark">
      <b-navbar-brand class="ml-2 align-items-center" >
        <a href="/"><img src="/symbol-bw.svg" height="32" alt="Branding"></a>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-up" class="text-dark"></b-icon>
          <b-icon v-else icon="chevron-down" class="text-dark"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown>
            <template slot="button-content">
              About
            </template>
            <b-dropdown-header>About</b-dropdown-header>
            <b-dropdown-item to="/about">About Airframes</b-dropdown-item>
            <b-dropdown-item href="https://docs.airframes.io/docs/intro" target="_blank">Docs</b-dropdown-item>
            <b-dropdown-item to="/faq">FAQ</b-dropdown-item>
            <b-dropdown-item to="/partners">Partners</b-dropdown-item>
            <b-dropdown-item to="/services">Services</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>Community</b-dropdown-header>
            <b-dropdown-item href="https://community.airframes.io" target="_blank">Forums</b-dropdown-item>
            <b-dropdown-item href="https://github.com/airframesio" target="_blank">GitHub</b-dropdown-item>
            <b-dropdown-item href="https://www.patreon.com/Airframes" target="_blank">Patreon</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>Socials</b-dropdown-header>
            <b-dropdown-item href="https://discord.gg/airframes" target="_blank">Discord</b-dropdown-item>
            <b-dropdown-item href="https://airwaves.social/@airframes" target="_blank">Mastodon</b-dropdown-item>
            <b-dropdown-item href="https://x.com/airframesio" target="_blank">X</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown>
            <template slot="button-content">
              Data
            </template>
            <b-dropdown-header>Core</b-dropdown-header>
            <b-dropdown-item to="/airframes">Airframes</b-dropdown-item>
            <b-dropdown-item to="/airlines">Airlines</b-dropdown-item>
            <b-dropdown-item to="/airports">Airports</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>Community</b-dropdown-header>
            <b-dropdown-item to="/messages/historical">Messages</b-dropdown-item>
            <b-dropdown-item to="/stations">Stations</b-dropdown-item>
            <b-dropdown-item to="/users">Users</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown>
            <template slot="button-content">
              Search
            </template>
            <b-dropdown-item to="/messages/historical">Messages</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown>
            <template slot="button-content">
              Maps
            </template>
            <b-dropdown-item to="/flights">Flight Map</b-dropdown-item>
            <b-dropdown-item href="https://alpha.globe.airframes.io" target="_blank">Globe (alpha)</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto mr-2 align-items-center d-none d-lg-flex">
          <b-nav-item href="https://github.com/airframesio" target="_blank">
            <img alt="GitHub Org's stars" src="https://img.shields.io/github/stars/airframesio?style=flat-square&label=GitHub%20Stars">
          </b-nav-item>
          <b-nav-item href="https://airwaves.social/@airframes" target="_blank" style="margin-left: 20px; margin-top: -2px;">
            <img alt="Mastodon" src="https://img.shields.io/mastodon/follow/109519694245190348?domain=https%3A%2F%2Fairwaves.social&style=flat-square&label=Mastodon">
          </b-nav-item>
          <b-nav-item href="https://twitter.com/airframesio" target="_blank" style="margin-left: 10px; margin-top: -2px;">
            <img alt="X" src="https://img.shields.io/twitter/follow/airframesio?style=flat-square&label=X">
          </b-nav-item>
          <b-nav-item class="mr-2" href="https://discord.gg/airframes" target="_blank" style="margin-left: 10px; margin-top: -2px;">
            <img alt="Discord" src="https://img.shields.io/discord/1067697487927853077?color=%23031137&style=flat-square&label=Discord">
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto mr-2">
          <b-nav-item href="https://shop.airframes.io" target="_blank">Shop</b-nav-item>
          <b-nav-item-dropdown
            id="user-menu-dropdown"
            :text="$store.state.auth.user.username"
            right
            class="ml-4"
            v-if="$store.state.auth.status.loggedIn"
          >
            <template slot="button-content">
              <img
                :src="avatar.imageUrl($store.state.auth.user, { size: 24 })"
                class="rounded"
                alt="User Avatar"
                />
              {{ $store.state.auth.user.username }}
            </template>
            <b-dropdown-item to="/user/dashboard">Dashboard</b-dropdown-item>
            <b-dropdown-item to="/myip">My IP</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/about">About</b-dropdown-item>
            <b-dropdown-item to="https://shop.airframes.io" target="_blank">Shop</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="handleLogout">
              Logout
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/auth/login" v-else>Login</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script lang="ts">
import Avatar from '@/utilities/avatar';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Avatar,
  },
})
export default class App extends Vue {
  avatar = Avatar;

  handleLogout() {
    this.$store.dispatch('auth/logout');
    this.$router.push('/auth/login');
  }
}
</script>

<style>
.router-link-active {
  font-weight: bold !important;
  color: #000 !important;
}
</style>
