<template>
  <div style="height: 100%;">
    <FlightsNav style="height: 97px;" />
    <div style="height: -webkit-calc(100% - 97px); height: calc(100% - 97px);">
      <splitpanes class="default-theme" vertical style="height: 100%;">
        <pane size="60">
          <FlightsMap :flights="flights" style="height: 100%;" />
        </pane>
        <pane size="40" min-size="25">
          <div class="w-full text-center bg-dark text-light py-1 font-bold">
            <span v-if="fetchingFlights">
              Retrieving flights
            </span>
            <span v-else>
              {{ flights.length }} FLIGHTS
            </span>
          </div>
          <FlightsTable :flights="flights" style="height: 100%;" />
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import FlightsMap from '@/components/flights/FlightsMap.vue';
import FlightsNav from '@/components/flights/FlightsNav.vue';
import FlightsTable from '@/components/flights/FlightsTable.vue';

@Component({
  components: {
    Splitpanes,
    Pane,
    FlightsMap,
    FlightsNav,
    FlightsTable,
  },
})
export default class Flights extends Vue {
  errorFetchingFlights = false;

  fetchInterval?: number;

  fetchingFlights = false;

  flights: any[] = [];

  mounted() {
    this.flights = [];
    this.fetchFlights();
    this.clearIntervals();
    this.fetchInterval = window.setInterval(() => {
      this.fetchFlights();
    }, 15000);
  }

  clearIntervals() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  async fetchFlights() {
    this.fetchingFlights = true;
    let headers = {};
    if (this.$store.state.auth
        && this.$store.state.auth.user
        && this.$store.state.auth.user.apiKey
    ) {
      headers = {
        'X-API-KEY': this.$store.state.auth.user.apiKey,
      };
    }
    const response = await axios.get(`${this.$store.state.apiServerBaseUrl}/flights/active`, {
      headers,
    });
    const results = response.data;
    console.log('Fetched flights', results);
    this.fetchingFlights = false;
    this.flights = results;
  }

  beforeDestroy() {
    this.clearIntervals();
  }

  beforeUnmount() {
    this.clearIntervals();
  }

  destroyed() {
    this.clearIntervals();
  }

  unmounted() {
    this.clearIntervals();
  }
}
</script>
