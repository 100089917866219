<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div>
    <MessagesNav />
    <div v-if="fetchingMessage" class="m-4 p-4 text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="noMessageFound" class="m-4 p-4 text-center">
      <h1>404</h1>
      <h2>Message Not Found</h2>
    </div>
    <div v-else class="m-4 text-left">
      <table class="table">
        <tr class="bg-dark text-light">
          <th>ID</th>
          <th>Timestamp</th>
          <th>Airframe</th>
          <th>Flight</th>
          <th>Label</th>
          <th>Block ID</th>
          <th>Message Number</th>
          <th class="text-right">Error</th>
        </tr>
        <tr class="bg-light">
          <td>{{ message.id }}</td>
          <td>
            <span :title="message.timestamp">
              {{ message.timestamp | moment("MMM DD YYYY, HH:mm:ss") }}
            </span>
          </td>
          <td>
            <span v-if="message.airframe">
              <router-link :to="`/airframes/${message.airframe.id}`">
                {{ message.airframe.tail }}
              </router-link>
            </span>
          </td>
          <td>
            <span v-if="message.flight">
              <router-link :to="`/flights/${message.flight.id}`">
                {{ message.flight.flight }}
              </router-link>
            </span>
          </td>
          <td>{{ message.label }}</td>
          <td>{{ message.blockId }}</td>
          <td>{{ message.messageNumber }}</td>
          <td class="text-right">{{ message.error }}</td>
        </tr>
      </table>
      <table class="table mb-2">
        <tr class="bg-dark text-light">
          <th>Station</th>
          <th>User</th>
          <th>Source</th>
          <th>Client</th>
          <th class="text-right">Frequency</th>
        </tr>
        <tr v-if="message.station" class="bg-light">
          <td>
            <router-link :to="`/stations/${message.station.ident}`">
              {{ message.station.ident }}
            </router-link>
          </td>
          <td>
            <div v-if="message.station.user">
              <router-link :to="`/users/${message.station.user.username}`">
                <img
                  :src="message.station.user.gravatarUrl"
                  class="rounded-circle"
                  width="20"
                  height="20"
                  :alt="message.station.user.username"
                  />
                {{ message.station.user.username }}
              </router-link>
            </div>
          </td>
          <td>{{ message.sourceType ? message.sourceType.toUpperCase() : "" }}</td>
          <td>{{ message.source }}</td>
          <td class="text-right">{{ message.frequency }}</td>
        </tr>
      </table>
      <div class="mb-2">
        <div class="bg-dark p-2">
          <strong class="text-light">Text</strong>
        </div>
        <div class="p-2">
          <div class="text-wrap text-break" v-html="convertNewlinesToBRs(message.text)" />
        </div>
      </div>
      <div class="mb-4">
        <div class="bg-dark p-2">
          <strong class="text-light">Text Decoded</strong>
        </div>
        <div class="p-2">
          <div v-if="decodedMessage.decoded">
            <MessageDecodingTable :decodedMessage="decodedMessage" />
          </div>
          <div v-else class="text-muted">
            {{ decodedMessage.error }}.
          </div>

          <div class="mt-2 small">
            The decoder for the message text is currently in beta. If you believe you have info
            to help decode this message, please consider helping to
            <a href="https://github.com/airframesio/acars-message-documentation" target="_blank">document the
            format of this message text</a>, after which if you are feeling like you have the chops,
            <a href="https://github.com/airframesio/acars-decoder-typescript" target="_blank">submit a Pull Request
            to add decoding support</a>.
          </div>

          <div v-if="!decoderDebug" class="mt-2">
            <a
              class="small"
              style="cursor: pointer;" @click="showDecoderDebug">Show Decoder Debug</a>
          </div>
          <div v-else class="mt-2">
            <a
              style="cursor: pointer;"
              class="small" @click="hideDecoderDebug">Hide Decoder Debug</a>
            <div class="mt-1">
              <strong>Decoder Debug</strong>
              <pre><code>{{ JSON.stringify(decodeMessage(message), null, 2) }}
                </code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import VueAxios from 'vue-axios';

import { MessageDecoder } from '@airframes/acars-decoder';

import MessageDecodingTable from '@/components/messages/MessageDecodingTable.vue';
import MessagesNav from '@/components/MessagesNav.vue';

Vue.use(VueAxios, axios);

@Component({
  components: {
    MessageDecodingTable,
    MessagesNav,
  },
})
export default class MessageDetail extends Vue {
  decoded = { decoded: false };

  fetchingMessage = false;

  errorFetchingMessage = false;

  errorFetchingMessageMessage = '';

  message = {};

  noMessageFound = false;

  decoderDebug = false;

  beforeMount() {
    this.message = {};
  }

  created() {
    this.refresh();
  }

  @Watch('$route')
  onPropertyChanged(newValue, oldValue) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.refresh();
    }
  }

  convertNewlinesToBRs(text) { // eslint-disable-line class-methods-use-this
    return text ? text.split('\n').join('<br>') : '';
  }

  decode(message) { // eslint-disable-line class-methods-use-this
    return new MessageDecoder(this.$store).decode(message);
  }

  get decodedMessage() {
    return this.decoded;
  }

  decodeMessage(message) { // eslint-disable-line class-methods-use-this
    const results = new MessageDecoder(this.$store).decode(message);
    delete results.message.station.latitude;
    delete results.message.station.longitude;
    return results;
  }

  hideDecoderDebug() {
    this.decoderDebug = false;
  }

  refresh() {
    this.fetchingMessage = true;
    Vue.axios.get(`${this.$store.state.apiServerBaseUrl}/messages/${this.$route.params.id}`).then((response) => {
      this.message = response.data;
      this.decoded = this.decode(this.message);
      if (!this.message) {
        this.noMessageFound = true;
      }
      this.fetchingMessage = false;
    });
  }

  showDecoderDebug() {
    this.decoderDebug = true;
  }
}
</script>
