<template>
  <div id="settings-nav">
    <div class="w-full">
      <router-link :to="`/user/settings/profile`"
        class="rounded">Public Profile</router-link>
    </div>
    <div>
      <router-link :to="`/user/settings/notifications`"
        class="rounded">Notifications</router-link>
    </div>
    <div>
      <router-link :to="`/user/settings/integrations`"
        class="rounded">Integrations</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class UserSettingsNav extends Vue {

}
</script>

<style scoped>

#settings-nav {
  width: 240px;
  margin: 0;
  padding: 0;
}

#settings-nav a {
  text-decoration: none;
  color: #333;
  display: block;
  box-sizing: border-box;
  margin: 0 !important;
  padding: 6px 8px;

  &.router-link-exact-active {
    font-weight: semibold;
    background-color: #ccc;
  }

  &:hover {
    background-color: #ddd;
  }
}

</style>
