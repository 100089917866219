<template>
  <div class="user-settings text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Settings</h3>
      <router-link to="/user/dashboard">Account</router-link> |
      <router-link :to="`/user/settings`">Settings</router-link>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <UserSettingsNav />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import UserSettingsNav from '@/components/user/settings/UserSettingsNav.vue';

@Component({
  components: {
    UserSettingsNav,
  },
})
export default class UserSettings extends Vue {

}
</script>
