<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="user-settings text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Settings</h3>
      <router-link to="/user/dashboard">Account</router-link> |
      <router-link to="/user/settings">Settings</router-link> |
      Public Profile
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <div class="row">
          <div class="col col-md-3">
            <UserSettingsNav />
          </div>
          <div class="col">
            <h3>
              Public Profile
            </h3>
            <hr/>
            <div class="mb-4">
              <strong><label for="username">Username</label></strong>
              <input
                id="username"
                name="username"
                type="text"
                class="form-control"
                disabled="true"
                :value="$store.state.auth.user.username"
                />
            </div>
            <div class="mb-4">
              <strong><label for="name">Name</label></strong>
              <input
                id="name"
                name="name"
                type="text"
                class="form-control"
                placeholder="John Doe"
                :value="$store.state.auth.user.name"
                />
            </div>
            <div class="mb-4">
              <strong><label for="bio">Bio</label></strong>
              <input
                id="bio"
                name="bio"
                type="text"
                class="form-control"
                placeholder="Radio addict and licensed ham radio enthusiast"
                />
            </div>
            <div class="mb-4">
              <strong><label for="location">Location</label></strong>
              <input
                id="location"
                name="location"
                type="text"
                class="form-control"
                placeholder="San Francisco, CA"
                />
            </div>
            <div class="mb-4">
              <strong><label for="website_url">Website URL</label></strong>
              <input
                id="website_url"
                name="website_url"
                type="text"
                class="form-control"
                placeholder="https://mywebsite.com/johndoe"
                />
            </div>
            <div class="mb-4">
              <strong><label for="x_username">Twitter/X Username</label></strong>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">https://x.com/</span>
                <input
                  id="x_username"
                  name="x_username"
                  type="text"
                  class="form-control"
                  placeholder="johndoe"
                  />
                </div>
              </div>
            </div>
            <div class="mb-4">
              <strong><label for="mastodon_url">Mastodon URL</label></strong>
              <input
                id="mastodon_url"
                name="mastodon_url"
                type="text"
                class="form-control"
                placeholder="https://airwaves.social/@johndoe"
                />
            </div>
            <div class="mb-0">
              <strong><label for="">Avatar</label></strong>
            </div>
            <div class="mb-4">
              Avatars are currently provided by <a href="https://gravatar.com/" target="_blank">Gravatar</a> which
              is based on your account's email address.
              You can set your avatar at <a href="https://gravatar.com/" target="_blank">Gravatar</a>.
              In the future we will allow users to upload their own avatars to override this.
            </div>

            <div class="mb-4">
              <input type="submit" class="btn btn-primary" value="Save">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import UserSettingsNav from '@/components/user/settings/UserSettingsNav.vue';

@Component({
  components: {
    UserSettingsNav,
  },
})
export default class UserSettingsProfile extends Vue {

}
</script>

<style scoped>

</style>
