<template>
  <div>
    <b-table
      :selectable="false"
      :items="myProvider()"
      :fields="fieldsDefinition"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-null-last="true"
      bordered
      responsive="sm"
      small
      >
      <template #cell(user)="row">
        <span v-if="row.item">
          <router-link :to="`/users/${row.item.username}`" target="_blank">
            <img
              :src="row.item.gravatarUrl"
              width="20" height="20"
              :alt="`Avatar for ${row.item.username}`"
              class="rounded-circle"
              />
            {{ row.item.username }}
          </router-link>
        </span>
      </template>
      <template #cell(tags)="row">
        <span v-if="row.item">
          <span v-for="tag in row.item.tags" :key="tag">
            <span
              v-if="tag === 'Admin'"
              class="badge badge-danger text-white mr-2 px-2">
              {{ tag }}
            </span>
            <span
              v-else-if="tag === 'Feeder'"
              class="badge badge-success text-white mr-2 px-2">
              {{ tag }}
            </span>
            <span
              v-else-if="tag === 'Inactive Feeder'"
              class="badge badge-secondary text-white mr-2 px-2">
              {{ tag }}
            </span>
            <span
              v-else
              class="badge badge-info text-white mr-2 px-2">
              {{ tag }}
            </span>
          </span>
        </span>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment-timezone';

import { TextFormatter } from '@/utilities/text_formatter';
import { HostUtils } from '@/utilities/host_utils';

@Component
export default class UsersTable extends Vue {
  @Prop() users!: Array<any>;

  sortBy = 'stationsCount';

  sortDesc = true;

  fieldsDefinition = [
    {
      key: 'user',
      label: 'User',
      sortable: true,
      class: 'text-left',
      sortByFormatted: (value: any, key: any, item: any) => {
        if (item.username) {
          return item.username;
        }
        return '';
      },
    },
    {
      key: 'tags',
      label: 'Tags',
      sortable: true,
      class: 'text-left',
    },
    {
      key: 'stationsActiveCount',
      label: '# of Active Stations',
      sortable: true,
      class: 'text-right',
    },
    {
      key: 'stationsInactiveCount',
      label: '# of Inactive Stations',
      sortable: true,
      class: 'text-right',
    },
    {
      key: 'totalMessagesCount',
      label: 'Total # of Messages',
      sortable: true,
      class: 'text-right',
      formatter: (value: any, key: any, item: any) => TextFormatter.intToHuman(value),
    },
  ];

  statusString(status: String) { // eslint-disable-line class-methods-use-this
    let statusString = 'Unknown';

    switch (status) {
    case 'active':
      statusString = 'Active';
      break;
    case 'inactive':
      statusString = 'Inactive';
      break;
    case 'pending-confirmation':
      statusString = 'Pending Confirmation';
      break;
    default:
      statusString = 'Unknown';
      break;
    }

    return statusString;
  }

  statusColorClass(status: String) { // eslint-disable-line class-methods-use-this
    let colorClass = '';

    switch (status) {
    case 'active':
      colorClass = 'success';
      break;
    case 'inactive':
      colorClass = 'danger';
      break;
    case 'pending-confirmation':
      colorClass = 'warning';
      break;
    default:
      colorClass = '';
      break;
    }

    return colorClass;
  }

  myProvider() {
    const items = [];
    for (const user of this.users) { // eslint-disable-line no-restricted-syntax
      const mergedUser = {
        id: user.id,
        gravatarUrl: user.gravatarUrl,
        username: user.username,
        name: user.name,
        status: user.status,
        stationsCount: user.stations.length,
        stationsActiveCount: user.stations.filter((s: any) => s.status === 'active').length,
        stationsInactiveCount: user.stations.filter((s: any) => s.status !== 'active').length,
        tags: this.tagsForUser(user),
        // eslint-disable-next-line max-len
        totalMessagesCount: user.stations.reduce((acc: any, station: any) => (acc + (station && station.messagesCount ? parseInt(station.messagesCount, 10) : 0)), 0),
        updatedAt: user.updatedAt,
        _cellVariants: { status: this.statusColorClass(user.status) },
      };
      items.push(mergedUser);
    }
    return items || [];
  }

  tagsForUser(user: any) {
    const tags = [];
    if (this.userIsAdmin(user)) {
      tags.push('Admin');
    }
    if (this.userIsFeeder(user)) {
      tags.push('Feeder');
    } else if (this.userIsInactiveFeeder(user)) {
      tags.push('Inactive Feeder');
    } else {
      tags.push('Observer');
    }
    return tags;
  }

  // eslint-disable-next-line class-methods-use-this
  userIsAdmin(user: any) {
    return user.role.toLowerCase() === 'admin';
  }

  // eslint-disable-next-line class-methods-use-this
  userIsFeeder(user: any) {
    return user.stations
           && user.stations.length > 0
           && user.stations.filter((s: any) => s.status === 'active').length > 0;
  }

  // eslint-disable-next-line class-methods-use-this
  userIsInactiveFeeder(user: any) {
    return user.stations
           && user.stations.length > 0
           && user.stations.filter((s: any) => s.status !== 'active').length > 0;
  }
}
</script>

<style scoped>

</style>
