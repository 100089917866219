<!-- eslint-disable max-len -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="user-settings text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Settings</h3>
      <router-link to="/user/dashboard">Account</router-link> |
      <router-link to="/user/settings">Settings</router-link> |
      Notifications
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <div class="row">
          <div class="col col-md-3">
            <UserSettingsNav />
          </div>
          <div class="col">
            <h3>
              Notifications
            </h3>
            <hr/>

            <div class="section mb-4">
              <div class="mb-3">
                <div class="h5">Add a Notification</div>
                <p>
                  Create a notification to be sent to you when something
                  happens on the Airframes platform.
                </p>
              </div>
              <div class="mb-4 form-row align-items-center">
                <div class="col-auto">
                  <strong>When</strong>
                </div>
                <div class="col-auto">
                  <select class="form-control mr-2">
                    <option
                      v-for="notificationTypes in notificationTypes"
                      :key="notificationTypes.id">
                      {{ notificationTypes.description }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <strong>send via</strong>
                </div>
                <div class="col-auto">
                  <select class="form-control mr-2">
                    <option v-for="mechanism in mechanisms" :key="mechanism.id">
                      {{ mechanism.id }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <strong>to</strong>
                </div>
                <div class="col-auto">
                  <input
                    id="notify_email_station_inactive_email"
                    name="notify_email_station_inactive_email"
                    type="email"
                    class="px-2 form-control"
                    placeholder="john@doe.com"
                    :value="$store.state.auth.user.email"
                    />
                </div>
              </div>

              <div class="mb-4">
                <input type="submit" class="btn btn-primary" value="Add Notification">
              </div>
            </div>
            <hr/>

            <div class="section mb-4">
              <div class="mb-3">
                <div class="h5">Your Notifications</div>
                <p>
                  These are your configured notifications.
                </p>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-sm">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Mechanism</th>
                      <th scope="col">Destination</th>
                      <th scope="col" class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="notification in notifications" :key="notification.id">
                      <td class="align-middle">
                        {{ notificationTypes.find(type => type.id === notification.type).name }}
                      </td>
                      <td class="align-middle">
                        {{ mechanisms.find(mechanism => mechanism.id === notification.mechanism).name }}
                      </td>
                      <td class="align-middle">
                        {{ notification.email }}
                      </td>
                      <td class="text-center">
                        <a href="#" class="btn btn-sm btn-danger">X</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import UserSettingsNav from '@/components/user/settings/UserSettingsNav.vue';

@Component({
  components: {
    UserSettingsNav,
  },
})
export default class UserSettingsNotifications extends Vue {
  mechanisms = [
    { id: 'email', name: 'Email' },
    { id: 'sms', name: 'Text' },
    { id: 'webhook', name: 'Webhook' },
  ];

  notifications = [
    {
      id: 'id1', type: 'station_inactive', mechanism: 'email', email: 'john@doe.com',
    },
    {
      id: 'id2', type: 'station_active', mechanism: 'email', email: 'john@doe.com',
    },
    {
      id: 'id3', type: 'station_inactive', mechanism: 'webhook', email: 'https://mysite.com/station/down',
    },
  ];

  notificationTypes = [
    { id: 'station_inactive', name: 'Station Inactive', description: 'one of your stations is inactive' },
    { id: 'station_active', name: 'Station Active', description: 'one of your stations has become active' },
    { id: 'specific_flight', name: 'Specific Flight', description: 'a specific flight' },
    { id: 'favorite_airframes', name: 'Favorite Airframes', description: 'favorited airframes are detected' },
    { id: 'favorite_flights', name: 'Favorite Flights', description: 'favorited flights are detected' },
  ];
}
</script>

<style scoped>

</style>
